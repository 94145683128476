import type {
  Group,
  SpecTableValue,
  SpecType
} from '@hypercodestudio/basler-components/dist/components/modules/ProductSpecs.vue';
import type { Attribute } from '@hypercodestudio/basler-components/dist/components/modules/ProductDetailFeatures.vue';
import type {
  AttributeSetFragment,
  AttributeSetMetadataFragment,
  AttributeSetMetadataUiOptionsFragment,
  CustomAttribute,
  CustomAttributeFragment,
  GroupInterface,
  Maybe
} from '~/lib/Shop/generated/schema';
import type { IndexedCustomAttributes } from '~/utils/shop/DecoratedProduct';
import { getIndexCustomAttributes } from '~/utils/shop/DecoratedProduct';
import type { PdpConfiguration } from '~/composables/usePdpConfiguration';
import { unique } from '~/utils/unique';
import {
  isDateString,
  isDateTimeString,
  mapToFormattedDate
} from '~/utils/mapper/mapToFormattedDate';
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import { isDefined } from '~/utils/guards/isDefined';
import { type BracketSchema, type RuleSchema } from '~/utils/rules/base';
import {
  SpecificationData,
  SpecificationRuleBuilder
} from '~/utils/rules/specifications';

type SpecificationGroup = {
  translationKey: string;
  values: string[];
  type?: 'table';
};

export type SpecificationMapping = {
  /**
   * A list of specifications that must match for this group to be displayed.
   */
  matchingSpecifications: RuleSchema | BracketSchema;
  showDescriptionInList?: boolean;
  highlighted?: {
    icon: string;
    code: string;
  }[];
  highlightedInList?: {
    icon: string;
    code: string;
  }[];
  groups: SpecificationGroup[];
};

export function flipAttributeSetMapping(
  cfg: Record<string, number>
): Record<number, string> {
  const ret: Record<number, string> = {};
  for (const key in cfg) {
    ret[cfg[key]] = key;
  }
  return ret;
}

function createGroup(
  group: Maybe<GroupInterface> | undefined,
  attributeMetadata: AttributeSetMetadataFragment[],
  type: SpecType,
  locale: LOCALE_CODE,
  link?: string
): Group {
  return {
    title: group?.attribute_group_name ?? '',
    type,
    specs: attributeMetadata
      ?.map((attribute) => createEntry(attribute, locale, link))
      .filter(isDefined)
      .filter((x) => x.value !== '')
  };
}

function hasAttributeOptions(
  input?: unknown | undefined | null
): input is AttributeSetMetadataUiOptionsFragment {
  return (
    typeof input === 'object' && input != null && 'attribute_options' in input
  );
}

function createEntry(
  attribute: AttributeSetMetadataFragment,
  locale: LOCALE_CODE,
  link?: string
): SpecTableValue {
  const options =
    attribute.selected_attribute_options?.attribute_option
      ?.map((entry) => entry?.label)
      .filter(isDefined) ?? [];

  // the current value might be a technical value, e.g. "0"
  // We can not query a label on "entered_attribute_value", so we have to find
  // a matching label from all available ui option.
  // @see https://gcp.baslerweb.com/jira/browse/WEB2-2461
  const currentValue = attribute.entered_attribute_value?.value;
  let valueLabel = currentValue;
  if (currentValue && hasAttributeOptions(attribute.ui_input)) {
    valueLabel =
      attribute.ui_input?.attribute_options?.find(
        (option) => option?.value === currentValue
      )?.label || currentValue;
  }

  if (
    isDefined(valueLabel) &&
    (isDateTimeString(valueLabel) || isDateString(valueLabel))
  ) {
    valueLabel = mapToFormattedDate(valueLabel, locale);
  }

  if (options?.length < 2) {
    let value: string = options[0] ?? valueLabel ?? '';
    if (attribute.code === 'sensor_framerate' && link) {
      value += link;
    }
    return {
      value: value,
      label: attribute.label ?? ''
    };
  }

  const value = options?.join('</li><li>') ?? valueLabel ?? '';

  return {
    value: `<ul><li>${value}</li></ul>`,
    label: attribute.label ?? ''
  };
}

export function filteredSpecificationMappings(
  indexedCustomAttributes: Readonly<IndexedCustomAttributes>,
  specificationMappings: ReadonlyArray<SpecificationMapping>,
  variables: Readonly<Record<string, string>>,
  attributeSets: Readonly<Record<string, number>>,
  attributeSetId: string
) {
  return specificationMappings.filter(({ matchingSpecifications }) =>
    SpecificationRuleBuilder.build(matchingSpecifications, variables).matches(
      new SpecificationData(
        indexedCustomAttributes,
        attributeSets,
        attributeSetId
      )
    )
  );
}

export function extractLabelValueFromCustomAttribute(
  customAttribute: Readonly<CustomAttributeFragment>,
  locale: LOCALE_CODE
): {
  value: string | undefined | null;
  label: string;
  key: string | undefined | null;
} {
  const label = customAttribute?.attribute_metadata?.label ?? '';
  let value =
    customAttribute?.selected_attribute_options?.attribute_option
      ?.map((option: { label?: string | null } | null) => {
        if (option === null) {
          return undefined;
        }
        return typeof option?.label === 'string' ? option?.label : undefined;
      })
      .filter(isDefined)
      ?.join(' ') ?? customAttribute?.entered_attribute_value?.value;

  if (isDefined(value) && (isDateTimeString(value) || isDateString(value))) {
    value = mapToFormattedDate(value, locale);
  }
  return { label, value, key: customAttribute?.attribute_metadata?.code };
}

export function createHighlightedSpecifications(
  {
    variables,
    specificationMappings,
    attributeSets
  }: Readonly<PdpConfiguration>,
  customAttributes: ReadonlyArray<CustomAttributeFragment>,
  attributeSetId: string,
  locale: LOCALE_CODE,
  highlightContext: 'highlighted' | 'highlightedInList' = 'highlighted',
  variants: ReadonlyArray<ReadonlyArray<CustomAttributeFragment>> = []
): Attribute[] {
  const indexedCustomAttributes = getIndexCustomAttributes(
    customAttributes as CustomAttribute[]
  );
  const indexedVariantCustomAttributes = variants?.map((v) =>
    getIndexCustomAttributes((v as CustomAttributeFragment[]) ?? [])
  );

  const tmpIdx: { [index: string]: boolean } = {};

  return filteredSpecificationMappings(
    indexedCustomAttributes,
    specificationMappings,
    variables,
    attributeSets,
    attributeSetId
  )
    .map((specificMapping) => specificMapping[highlightContext])
    .flat()
    .map((v) => {
      if (isDefined(v) && !tmpIdx[v.code]) {
        tmpIdx[v.code] = true;
        return v;
      }
      return undefined;
    })
    .filter(isDefined)
    .map(({ icon, code }) => {
      const customAttribute = indexedCustomAttributes[code];
      let { label, value } = extractLabelValueFromCustomAttribute(
        customAttribute,
        locale
      );

      // take from variants in case base product has no value
      if (!value && indexedVariantCustomAttributes.length > 0) {
        value = unique(
          indexedVariantCustomAttributes
            ?.map((i) => {
              const variantIndexedCustomAttribute = i[code];
              const tmp = extractLabelValueFromCustomAttribute(
                variantIndexedCustomAttribute,
                locale
              );
              if (!label) {
                label = tmp.label;
              }
              return tmp.value;
            })
            .filter(isDefined)
            .sort()
        ).join(', ');
      }

      if (!value) {
        return undefined;
      }

      return {
        value,
        label,
        key: highlightContext === 'highlightedInList' ? code : icon
      };
    })
    .filter(isDefined);
}

export function createSpecifications(
  attributeSet: AttributeSetFragment[],
  type: SpecType,
  locale: LOCALE_CODE,
  link?: string
): Group[] {
  return attributeSet
    .map((attr) =>
      createGroup(
        attr.group,
        attr.attribute_metadata?.filter(isDefined) ?? [],
        type,
        locale,
        link
      )
    )
    .flat()
    .filter(
      (group) =>
        group.specs?.length !== 0 &&
        group.specs?.some((spec) => spec.value !== '')
    );
}
