import { StatusCodes } from 'http-status-codes';
import type { RouteMiddleware } from 'nuxt/app';
import { defineNuxtRouteMiddleware } from '#imports';
import { enhancedNavigateTo } from '~/utils/enhancedNavigateTo';

const matchAllTrailingSlashes = /(\/\/+)$/;

export const trailingSlashHandlerMiddleware: RouteMiddleware = (to) => {
  if (
    to.path === '/' ||
    [
      'lighting-advisor',
      'assets',
      'fonts',
      'images',
      'videos',
      'favicon',
      'api',
      'auth'
    ].some((partialPath) => to.path.replace(/^\//, '').startsWith(partialPath))
  ) {
    return;
  }

  let redirectPath: string | undefined;

  if (!to.path.endsWith('/')) {
    redirectPath = `${to.path}/`;
  } else if (matchAllTrailingSlashes.test(to.path)) {
    redirectPath = to.path.replace(matchAllTrailingSlashes, '/');
  }

  if (redirectPath) {
    return enhancedNavigateTo(
      {
        query: to.query,
        path: redirectPath
      },
      {
        replace: true,
        redirectCode: StatusCodes.MOVED_PERMANENTLY
      }
    );
  }
};

export default defineNuxtRouteMiddleware(trailingSlashHandlerMiddleware);
