import { default as _91id_93VuMcHo0psKMeta } from "/app/pages/[locale]/_technical/html/[id].vue?macro=true";
import { default as _91_46_46_46slug_93KPiLDrqIuGMeta } from "/app/pages/[locale]/[...slug].vue?macro=true";
import { default as _91id_93F2yqfMP2JCMeta } from "/app/pages/[locale]/id/[id].vue?macro=true";
import { default as _91urlKey_936z5fV8kjkaMeta } from "/app/pages/[locale]/shop/[urlKey].vue?macro=true";
import { default as component_45stubTOq3NWgWLHMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _91id_93VuMcHo0psKMeta?.name ?? "locale-_technical-html-id",
    path: _91id_93VuMcHo0psKMeta?.path ?? "/:locale()/_technical/html/:id()",
    meta: _91id_93VuMcHo0psKMeta || {},
    alias: _91id_93VuMcHo0psKMeta?.alias || [],
    redirect: _91id_93VuMcHo0psKMeta?.redirect,
    component: () => import("/app/pages/[locale]/_technical/html/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93KPiLDrqIuGMeta?.name ?? "locale-slug",
    path: _91_46_46_46slug_93KPiLDrqIuGMeta?.path ?? "/:locale()/:slug(.*)*",
    meta: _91_46_46_46slug_93KPiLDrqIuGMeta || {},
    alias: _91_46_46_46slug_93KPiLDrqIuGMeta?.alias || [],
    redirect: _91_46_46_46slug_93KPiLDrqIuGMeta?.redirect,
    component: () => import("/app/pages/[locale]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93F2yqfMP2JCMeta?.name ?? "locale-id-id",
    path: _91id_93F2yqfMP2JCMeta?.path ?? "/:locale()/id/:id()",
    meta: _91id_93F2yqfMP2JCMeta || {},
    alias: _91id_93F2yqfMP2JCMeta?.alias || [],
    redirect: _91id_93F2yqfMP2JCMeta?.redirect,
    component: () => import("/app/pages/[locale]/id/[id].vue").then(m => m.default || m)
  },
  {
    name: _91urlKey_936z5fV8kjkaMeta?.name ?? "locale-shop-urlKey",
    path: _91urlKey_936z5fV8kjkaMeta?.path ?? "/:locale()/shop/:urlKey()",
    meta: _91urlKey_936z5fV8kjkaMeta || {},
    alias: _91urlKey_936z5fV8kjkaMeta?.alias || [],
    redirect: _91urlKey_936z5fV8kjkaMeta?.redirect,
    component: () => import("/app/pages/[locale]/shop/[urlKey].vue").then(m => m.default || m)
  },
  {
    name: component_45stubTOq3NWgWLHMeta?.name ?? undefined,
    path: component_45stubTOq3NWgWLHMeta?.path ?? "/auth/login",
    meta: component_45stubTOq3NWgWLHMeta || {},
    alias: component_45stubTOq3NWgWLHMeta?.alias || [],
    redirect: component_45stubTOq3NWgWLHMeta?.redirect,
    component: () => import("/app/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubTOq3NWgWLHMeta?.name ?? undefined,
    path: component_45stubTOq3NWgWLHMeta?.path ?? "/auth/logout",
    meta: component_45stubTOq3NWgWLHMeta || {},
    alias: component_45stubTOq3NWgWLHMeta?.alias || [],
    redirect: component_45stubTOq3NWgWLHMeta?.redirect,
    component: () => import("/app/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]