import type { MoneyFragment } from '~/lib/Shop/generated/schema';

export function formatMoney(
  money?: MoneyFragment | null,
  locale = 'en-us',
  withCurrency = true
): string {
  if (!money || money.value == null) {
    return '';
  }

  const { currency, value } = money;

  if (currency) {
    try {
      const styling = {
        style: 'currency',
        currency
      } as Intl.NumberFormatOptions;
      const formatter = new Intl.NumberFormat(locale, styling);
      const resolvedOptions = formatter.resolvedOptions();

      // Der Style muss entfernt werden, ansonsten wird das CurrencySign ausgegeben
      return withCurrency
        ? formatter.format(value)
        : value.toLocaleString(locale, {
            ...resolvedOptions,
            style: undefined
          });
    } catch (_e) {
      // XXX: ignored for now
    }
  }

  return withCurrency ? `${value} ${currency}` : `${value}`;
}
