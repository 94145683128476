export interface Rule<T> {
  matches(data: T): boolean;
}

export interface Bracket<T> {
  setChildren(children: Rule<T>[]): void;
}

export class AndBracket<T> implements Rule<T>, Bracket<T> {
  private children: Rule<T>[] = [];

  matches(data: T): boolean {
    return this.children.every((child) => child.matches(data));
  }

  setChildren(children: Rule<T>[]): AndBracket<T> {
    this.children = children;
    return this;
  }
}

export class OrBracket<T> implements Rule<T>, Bracket<T> {
  private children: Rule<T>[] = [];

  matches(data: T): boolean {
    return this.children.some((child) => child.matches(data));
  }

  setChildren(children: Rule<T>[]): OrBracket<T> {
    this.children = children;
    return this;
  }
}

export class EmptyRule implements Rule<any> {
  private readonly specificationRule: RuleSchema | BracketSchema;

  constructor(rule: RuleSchema | BracketSchema) {
    this.specificationRule = rule;
  }

  matches(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: any
  ): boolean {
    // eslint-disable-next-line no-console
    console.warn(
      'Rule / Bracket does not exist: ' + JSON.stringify(this.specificationRule)
    );
    return false;
  }
}

export function isAndBracket(t: any): t is BracketSchema {
  return t.connection === 'and' && Array.isArray(t.children);
}

export function isOrBracket(t: any): t is BracketSchema {
  return t.connection === 'or' && Array.isArray(t.children);
}

export type RuleSchema = {
  _comment?: string;
  key: string;
  operator: string;
  value: string | boolean | string[];
};

export type BracketSchema = {
  connection: 'and' | 'or';
  children: (RuleSchema | BracketSchema)[];
};
