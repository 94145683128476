import {
  AndBracket,
  type BracketSchema,
  EmptyRule,
  isAndBracket,
  isOrBracket,
  OrBracket,
  type Rule,
  type RuleSchema
} from '~/utils/rules/base';
import { isDefined } from '~/utils/guards/isDefined';
import { type LocationQuery } from 'vue-router';

export type SalesforceSla = '0' | '1' | '2' | '3' | '4' | '5' | '6';

export class UserData {
  public readonly isLoggedIn: boolean;
  public readonly salesforceSla: SalesforceSla | undefined;
  public readonly queryParameters: LocationQuery;

  public constructor({
    isLoggedIn,
    salesforceSla,
    queryParameters
  }: {
    isLoggedIn: boolean;
    salesforceSla?: SalesforceSla;
    queryParameters: LocationQuery;
  }) {
    this.isLoggedIn = isLoggedIn;
    this.salesforceSla = salesforceSla;
    this.queryParameters = queryParameters;
  }
}

class IsLoggedIn implements Rule<UserData> {
  private specificationRule: RuleSchema;

  constructor(rule: RuleSchema) {
    this.specificationRule = rule;
  }

  matches(data: UserData): boolean {
    let value = false;
    if (
      this.specificationRule.value === true ||
      this.specificationRule.value === 'true' ||
      this.specificationRule.value === '1'
    ) {
      value = true;
    }

    return value == data.isLoggedIn;
  }
}

class HasQueryParameter implements Rule<UserData> {
  private specificationRule: RuleSchema;

  constructor(rule: RuleSchema) {
    this.specificationRule = rule;
  }

  matches(data: UserData): boolean {
    return (
      this.specificationRule.key in data.queryParameters &&
      data.queryParameters[this.specificationRule.key]?.toString().trim() ==
        this.specificationRule.value?.toString().trim()
    );
  }
}

class HasSalesforceSla implements Rule<UserData> {
  private specificationRule: RuleSchema;

  constructor(rule: RuleSchema) {
    this.specificationRule = rule;
  }

  matches(data: UserData): boolean {
    if (!isDefined(data.salesforceSla)) {
      return false;
    }

    return ensureArray(this.specificationRule.value).includes(
      data.salesforceSla
    );
  }
}

function isHasSalesforceSlaRule(t: any): t is RuleSchema {
  return (
    t.operator === 'hasSalesforceSla' &&
    (typeof t.value === 'string' || Array.isArray(t.value))
  );
}

function isIsLoggedInRule(t: any): t is RuleSchema {
  return (
    t.operator === 'isLoggedIn' &&
    (typeof t.value === 'string' || typeof t.value === 'boolean')
  );
}

function isHasQueryParameterRule(t: any): t is RuleSchema {
  return (
    t.operator === 'hasQueryParameter' &&
    typeof t.key === 'string' &&
    typeof t.value === 'string'
  );
}

export class AudienceRuleBuilder {
  static build(mapping: Readonly<RuleSchema | BracketSchema>): Rule<UserData> {
    if (isAndBracket(mapping)) {
      return new AndBracket<UserData>().setChildren(
        mapping.children.map((c) => AudienceRuleBuilder.build(c))
      );
    }

    if (isOrBracket(mapping)) {
      return new OrBracket<UserData>().setChildren(
        mapping.children.map((c) => AudienceRuleBuilder.build(c))
      );
    }

    if (isHasSalesforceSlaRule(mapping)) {
      return new HasSalesforceSla(mapping);
    }

    if (isIsLoggedInRule(mapping)) {
      return new IsLoggedIn(mapping);
    }

    if (isHasQueryParameterRule(mapping)) {
      return new HasQueryParameter(mapping);
    }

    return new EmptyRule(mapping);
  }
}
