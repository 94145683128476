import type { IndexedCustomAttributes } from '~/utils/shop/DecoratedProduct';
import { isDefined } from '~/utils/guards/isDefined';
import { cloneDeep } from '~/utils/cloneDeep';
import {
  AndBracket,
  type BracketSchema,
  EmptyRule,
  isAndBracket,
  isOrBracket,
  OrBracket,
  type Rule,
  type RuleSchema
} from '~/utils/rules/base';

export class SpecificationData {
  public readonly customAttributes: Readonly<IndexedCustomAttributes>;
  public readonly attributeSetsMapping: Readonly<Record<string, number>>;
  public readonly attributeSetId: string;

  public constructor(
    customAttributes: Readonly<IndexedCustomAttributes>,
    attributeSetsMapping: Readonly<Record<string, number>>,
    attributeSetId: string
  ) {
    this.customAttributes = customAttributes;
    this.attributeSetsMapping = attributeSetsMapping;
    this.attributeSetId = attributeSetId;
  }
}

class IsOption implements Rule<SpecificationData> {
  private specificationRule: RuleSchema;

  constructor(rule: RuleSchema) {
    this.specificationRule = rule;
  }

  matches(data: SpecificationData): boolean {
    if (!data.customAttributes[this.specificationRule.key]) {
      return false;
    }
    return (
      data.customAttributes[
        this.specificationRule.key
      ].selected_attribute_options?.attribute_option?.find(
        (x) => x?.uid === this.specificationRule.value
      ) !== undefined
    );
  }
}

class HasAttributeSet implements Rule<SpecificationData> {
  private specificationRule: RuleSchema;

  constructor(rule: RuleSchema) {
    this.specificationRule = rule;
  }

  matches(data: SpecificationData): boolean {
    return (
      isDefined(
        data.attributeSetsMapping[this.specificationRule.value as string]
      ) &&
      data.attributeSetsMapping[
        this.specificationRule.value as string
      ].toString() === data.attributeSetId.toString()
    );
  }
}

function isIsOptionRule(t: any): t is RuleSchema {
  return (
    t.operator === 'isOption' &&
    typeof t.key === 'string' &&
    typeof t.value === 'string'
  );
}

function isHasAttributeSetRule(t: any): t is RuleSchema {
  return t.operator === 'hasAttributeSet' && typeof t.value === 'string';
}

export class SpecificationRuleBuilder {
  static build(
    mapping: Readonly<RuleSchema | BracketSchema>,
    variables: Record<string, string> = {}
  ): Rule<SpecificationData> {
    const clonedMapping = cloneDeep(mapping);

    if (
      isIsOptionRule(clonedMapping) &&
      (clonedMapping.value as string) in variables
    ) {
      clonedMapping.value = variables[clonedMapping.value as string];
    }

    if (isAndBracket(clonedMapping)) {
      return new AndBracket().setChildren(
        clonedMapping.children.map((c) =>
          SpecificationRuleBuilder.build(c, variables)
        )
      );
    }

    if (isOrBracket(clonedMapping)) {
      return new OrBracket().setChildren(
        clonedMapping.children.map((c) =>
          SpecificationRuleBuilder.build(c, variables)
        )
      );
    }

    if (isIsOptionRule(clonedMapping)) {
      return new IsOption(clonedMapping);
    }

    if (isHasAttributeSetRule(clonedMapping)) {
      return new HasAttributeSet(clonedMapping);
    }

    return new EmptyRule(clonedMapping);
  }
}
