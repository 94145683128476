import type { Ref } from 'vue';
import { Tracer } from '~/lib/Tracing';
import { type IAudience } from '~/lib/ContentfulService';

export function useAudiences(
  locale: string | undefined
): Promise<Ref<{ entries: IAudience[] | null } | null>> {
  const tracer = new Tracer('composables');

  return tracer.withSpan(
    'useAudiences',
    {
      'parameter.locale': locale
    },
    async () => {
      if (!locale) {
        return ref(null);
      }

      const { data, error } = await useFetch<{ entries: IAudience[] | null }>(
        '/api/contentful/audiences',
        {
          ...DEFAULT_FETCH_OPTIONS,
          query: { locale }
        }
      );

      if (error.value) {
        throw error.value;
      }

      if (!data.value) {
        throw createError('failed to load Audiences (useAudiences)');
      }

      return data;
    }
  );
}
