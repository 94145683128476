import type { ComputedRef } from 'vue';
import { useAudiences } from '~/composables/useAudiences';
import {
  AudienceRuleBuilder,
  type SalesforceSla,
  UserData
} from '~/utils/rules/audiences';
import { type BracketSchema, type RuleSchema } from '~/utils/rules/base';

export type AudienceId = string;
export type AudienceEntry = {
  label: string;
  description: string;
  id: AudienceId;
  matches: boolean;
};
export type AudiencesMap = Record<AudienceId, AudienceEntry>;

export default defineNuxtPlugin(async () => {
  const { $requestedLocale } = useNuxtApp();

  const { loggedIn, user } = useOidcAuth();
  const route = useRoute();

  const allAudiences = await useAudiences($requestedLocale.value);
  const audiences: ComputedRef<AudiencesMap> = computed<AudiencesMap>(() => {
    return (allAudiences.value?.entries ?? []).reduce((reducer, audience) => {
      let matches = false;
      if (audience.fields.audienceDefinition) {
        // TODO: valibot for schema parsing
        matches = AudienceRuleBuilder.build(
          audience.fields.audienceDefinition as Readonly<
            RuleSchema | BracketSchema
          >
        ).matches(
          new UserData({
            isLoggedIn: loggedIn.value,
            salesforceSla: user.value?.claims?.sf_SLA as
              | SalesforceSla
              | undefined,
            queryParameters: route.query
          })
        );
      }

      return {
        ...reducer,
        [audience.sys.id]: {
          id: audience.sys.id,
          label: audience.fields.internalName,
          description: audience.fields.description,
          matches
        }
      };
    }, {});
  });

  return {
    provide: {
      matchingAudiences: ref(
        Object.values(audiences.value)
          .filter((audience) => audience.matches)
          .map((audience) => audience.id)
      )
    }
  };
});
