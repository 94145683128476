import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';

import Aura from '@primevue/themes/aura';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, {
    // Default theme configuration
    theme: {
      preset: Aura,
      options: {
        prefix: 'p',
        darkModeSelector: 'light',
        cssLayer: false
      }
    }
  });

  // adds directive v-tooltip
  nuxtApp.vueApp.directive('tooltip', Tooltip);
});
