import { DecoratedProduct } from '~/utils/shop/DecoratedProduct';
import type { CommonProductFragment } from '~/lib/Shop/generated/schema';
import type {
  AddProductsOptions,
  CartType,
  ModifyCartItem,
  ModifyCartItemByDecoratedProduct
} from '~/stores/cartStore/model';
import { createAddToCartAnalyticsEvent } from './helper/createAddToCartAnalyticsEvent';
import type { ProductsBySkuResponseModel } from '~/server/api/magento/productsBySkus.get';
import { isDefined } from '~/utils/guards/isDefined';

export function useTriggerAddToCartEvent(
  parsedItems: ReadonlyArray<ModifyCartItem>,
  cartType: CartType,
  cartId: string,
  store: string,
  locale: string,
  options?: AddProductsOptions
) {
  const logger = useLogger();
  const { $analytics } = useNuxtApp();

  if (!$analytics) {
    logger.info('analytics not defined - skipping event');

    return;
  }

  const decoratedProducts: Record<string, DecoratedProduct> = {};
  const missingSkus: string[] = [];
  for (const cartItem of parsedItems) {
    if ('decoratedProduct' in cartItem) {
      decoratedProducts[cartItem.decoratedProduct.sku!] =
        cartItem.decoratedProduct;
    } else if ('sku' in cartItem) {
      missingSkus.push(cartItem.sku);
    }
  }

  // ensure, that cartItems has an actual product
  // and add the analytics event is asynchronously to avoid
  // blocking the ui flow
  (missingSkus.length
    ? $fetch<ProductsBySkuResponseModel>('/api/magento/productsBySkus', {
        retry: 0,
        // we remove a timeout here as magento might need some time to respond
        query: {
          store,
          locale,
          skus: missingSkus
        }
      })
    : Promise.resolve(null)
  )
    .then((resolvedProducts) => {
      // update map of resolved products
      resolvedProducts?.items?.filter(isDefined).forEach((product) => {
        decoratedProducts[product.sku!] = DecoratedProduct.build(product);
      });

      const products: ModifyCartItemByDecoratedProduct[] = [];
      for (const item of parsedItems) {
        if ('decoratedProduct' in item) {
          products.push(item);
        } else if ('sku' in item) {
          const resolvedProduct = decoratedProducts[item.sku];
          if (!resolvedProduct) {
            logger.warn(`failed to resolve product with sku ${item.sku}`);

            continue;
          }

          products.push({
            ...item,
            decoratedProduct: resolvedProduct
          });
        }
      }

      const event = createAddToCartAnalyticsEvent(
        cartType,
        cartId,
        products,
        options?.source
      );

      if (event) {
        $analytics.pushToDataLayer(event);
      }
    })
    .catch((e) => {
      logger.error(
        'failed to trigger analytics event for cart items',
        parsedItems,
        e
      );
    });
}
